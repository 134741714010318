var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.testator-desc")) + " ")]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', {
    attrs: {
      "for": "full_name"
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user_details.designation,
      expression: "user_details.designation"
    }],
    staticClass: "form-control",
    staticStyle: {
      "border-bottom-right-radius": "0",
      "border-top-right-radius": "0"
    },
    attrs: {
      "name": "",
      "id": "designation"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.user_details, "designation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeDesignation]
    }
  }, _vm._l(_vm.designations, function (designation) {
    return _c('option', {
      key: designation,
      domProps: {
        "value": designation
      }
    }, [_vm._v(" " + _vm._s(designation) + " ")]);
  }), 0)]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Penuh",
      "name": "fullname",
      "disabled": ""
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("mykad")) + " ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.ic_number,
      expression: "form.ic_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "disabled": ""
    },
    domProps: {
      "value": _vm.form.ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "ic_number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("old-ic")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.other_ic_number,
      expression: "form.other_ic_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "disabled": ""
    },
    domProps: {
      "value": _vm.form.other_ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "other_ic_number", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.user_details.gender.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "gender"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("gender")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.user_details.gender.$model,
      expression: "$v.user_details.gender.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "gender"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.user_details.gender, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.gender")) + " --")]), _vm._l(_vm.genders, function (gender, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": gender.split(':')[0]
      }
    }, [_vm._v(" " + _vm._s(gender.split(":")[1]) + " ")]);
  })], 2), _vm.$v.user_details.gender.$error && !_vm.$v.user_details.gender.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("gender")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("religion")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user_details.religion,
      expression: "user_details.religion"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "religion"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.user_details, "religion", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeReligion]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.religion")) + " --")]), _vm._l(_vm.religions, function (religion) {
    return _c('option', {
      key: religion,
      domProps: {
        "value": religion
      }
    }, [_vm._v(" " + _vm._s(religion) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "race"
    }
  }, [_vm._v(_vm._s(_vm.$t("race"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user_details.race,
      expression: "user_details.race"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "race",
      "id": "race"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.user_details, "race", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeRace]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.race")) + " --")]), _vm._l(_vm.races, function (race) {
    return _c('option', {
      key: race,
      domProps: {
        "value": race
      }
    }, [_vm._v(" " + _vm._s(race) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("nationality")) + " ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user_details.nationality,
      expression: "user_details.nationality"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "citizenship"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.user_details, "nationality", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(" -- " + _vm._s(_vm.$t("choose.nationality")) + " -- ")]), _vm._l(_vm.nationalities, function (nationality, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": nationality.split(':')[0]
      }
    }, [_vm._v(" " + _vm._s(nationality.split(":")[1]) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.user_details.email.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "lowercase",
      rawName: "v-lowercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.user_details.email.$model,
      expression: "$v.user_details.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.user_details.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.user_details.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.user_details.email.email ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.invalid-email")) + " ")]) : _vm._e(), _vm.$v.user_details.email.$error && !_vm.$v.user_details.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.user_details.mobile_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.user_details.mobile_number.$model,
      expression: "$v.user_details.mobile_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.user_details.mobile_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.user_details.mobile_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.user_details.mobile_number.$error && !_vm.$v.user_details.mobile_number.numeric ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-no")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("homeoffice-no")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user_details.home_phone_number,
      expression: "user_details.home_phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.user_details.home_phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.user_details, "home_phone_number", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.user_details.correspondence_address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mailing-address")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.user_details.correspondence_address.$model,
      expression: "\n                      $v.user_details.correspondence_address.$model\n                    ",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "rows": "2"
    },
    domProps: {
      "value": _vm.$v.user_details.correspondence_address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.user_details.correspondence_address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.user_details.correspondence_address.$error && !_vm.$v.user_details.correspondence_address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.correspondence-address")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.form.postcode,
      expression: "form.postcode",
      modifiers: {
        "lazy": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.form.postcode
    },
    on: {
      "blur": function ($event) {
        return _vm.getPostcode(1);
      },
      "change": function ($event) {
        return _vm.$set(_vm.form, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "city",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "state",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "address"
    }
  }, [_vm._v(_vm._s(_vm.$t("permanent-address")))]), _c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.sameAddress,
      expression: "form.sameAddress"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": "customCheck1",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.sameAddress) ? _vm._i(_vm.form.sameAddress, "1") > -1 : _vm.form.sameAddress
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.form.sameAddress,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "sameAddress", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "sameAddress", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "sameAddress", $$c);
        }
      }, _vm.copyAddress]
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "customCheck1"
    }
  }, [_vm._v(" Klik Jika Alamat Tetap Sama Seperti Di Atas ")])]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.user_details.correspondence_address,
      expression: "user_details.correspondence_address"
    }],
    staticClass: "form-control",
    attrs: {
      "readonly": _vm.form.sameAddress == 1,
      "rows": "2"
    },
    domProps: {
      "value": _vm.user_details.correspondence_address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.user_details, "correspondence_address", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode_2"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.user_details.correspondence_postcode,
      expression: "user_details.correspondence_postcode",
      modifiers: {
        "lazy": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode_2",
      "readonly": _vm.form.sameAddress == 1
    },
    domProps: {
      "value": _vm.user_details.correspondence_postcode
    },
    on: {
      "blur": function ($event) {
        return _vm.getPostcode(2);
      },
      "change": function ($event) {
        return _vm.$set(_vm.user_details, "correspondence_postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "city_2"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.user_details.correspondence_city,
      expression: "user_details.correspondence_city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "city_2",
      "readonly": ""
    },
    domProps: {
      "value": _vm.user_details.correspondence_city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.user_details, "correspondence_city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "state_2"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.user_details.correspondence_state,
      expression: "user_details.correspondence_state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "state_2",
      "readonly": ""
    },
    domProps: {
      "value": _vm.user_details.correspondence_state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.user_details, "correspondence_state", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykadcopy-front"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', [_c('small', [_c('i', [_vm._v("(" + _vm._s(_vm.$t("pls-separate-mykad")) + ")")])])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.mykad.frontUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.frontUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-1"
    }
  }) : _vm._e(), !_vm.mykad.frontUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("choose.mykadfront")))])]) : _vm._e(), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-1",
      "accept": "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'mykad-front');
      }
    }
  })])]), _vm.mykad.frontUrl ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": function ($event) {
        return _vm.openIc('front');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykadcopy-back"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', [_c('small', [_c('i', [_vm._v("(" + _vm._s(_vm.$t("pls-separate-mykad")) + ")")])])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.mykad.backUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.backUrlDisplay + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e(), !_vm.mykad.backUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("choose.mykadback")))])]) : _vm._e(), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-2",
      "accept": "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'mykad-back');
      }
    }
  })])]), _vm.mykad.backUrl ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": function ($event) {
        return _vm.openIc('back');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1) : _vm._e()])])])])])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [[0, 1].includes(_vm.amendment_status) ? _c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.updateUserDetails
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " ")]) : _vm._e()], 1)])])])]), _c('b-modal', {
    ref: "modalPostcode",
    staticClass: "modal fade",
    attrs: {
      "title": _vm.$t('add-newpostcode'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.storePostcode
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.postcode,
      expression: "formPostcode.postcode"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formPostcode.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.city,
      expression: "formPostcode.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.state,
      expression: "formPostcode.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "state", $event.target.value);
      }
    }
  })])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }